.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100%;
}

.container {
  display: flex;
  width: 100%;
  height: 100%;
}

.image {
  flex: 1;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  background: white;
  padding-top: 60px;
  background-position: bottom right;
  background-repeat: no-repeat;
}

.logoContainer {
  display: flex;
  width: 100%;
  min-height: 70px;
  padding-left: 40px;
  margin-bottom: 150px;
}

.logo {
  max-height: 70px;
}

@media (max-width: 650px) {
  .image {
    display: none;
  }
}
