.root {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  font-family: SourceSansPro, Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.72px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  min-width: 100px;
  box-sizing: border-box;
}

.root.selected {
  border-bottom: solid 3px #ff735c;
}
