.formContainer {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  z-index: 100;
}

.title {
  font-family: PublicoHeadlineWeb, "Times New Roman", Times, serif;
  font-size: 34px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #00008f;
  margin-bottom: 35px;
}

.input {
  width: 320px;
  height: 41px;
  border: solid 1px #ccc;
  background-color: #fff;
  margin-bottom: 18px;
  padding-left: 5px;
}

.errorInput {
  border: solid 1px #f40026;
}

.label {
  font-family: SourceSansPro, Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333;
  margin-bottom: 8px;
}

.button {
  width: 320px;
  height: 45px;
  background-color: #00008f;
  font-family: SourceSansPro, Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.22px;
  text-align: center;
  color: #fff;
  margin-top: 15px;
  border: none;
  cursor: pointer;
}

.forgotPassword {
  font-family: SourceSansPro, Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #00008f;
}

.errorContainer {
  display: flex;
  width: 100%;
  color: white;
  font-family: SourceSansPro, Arial, Helvetica, sans-serif;
  font-size: 12px;
  background-color: #ff735c;
  min-height: 40px;
  margin: 6px 0;
  margin-bottom: 10px;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
}

.passwordLabelContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.button:disabled,
.button[disabled] {
  border: 1px solid #999;
  background-color: #e1e1e1;
  color: white;
}
