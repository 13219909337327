.root {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  &__label {
    margin-bottom: 10px;
    font-family: SourceSansPro, Arial, Helvetica, sans-serif;
    text-transform: capitalize;
    font-weight: bold;
    color: #6d6d6d;
  }
}

.input {
  width: 100%;
  background: white;
  border: solid 1px #b5b5b5;
  height: 30px;
  min-width: 200px;
  padding: 0 10px;
  font-size: 12px !important;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.errorInput {
  border: solid 2px red;
}

.formInput {
  width: 100%;
  background: white;
  border: solid 1px #b5b5b5;
  height: 40px;
  min-width: 200px;
  padding: 0 10px;
  font-size: 12px !important;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.formSelectItem {
  font-size: 12px !important;
}
