.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
}

.errorCode {
  font-size: 20px;
  margin-top: 20px;
  color: #a2a2a2;
}

.errorCodeText {
  font-size: 35px;
}

.errorText {
  margin-top: 15px;
  color: #626262;
}

.button {
  border-radius: 3px;
  margin-top: 25px;
  width: 150px;
  height: 35px;
  color: white;
  background-color: #00008f;
  font-family: SourceSansPro, Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.22px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.list {
  max-width: 400px;
  margin-top: 20px;
  color: #626262;
}

.list > li {
  line-height: 35px;
}
