@use "styles/variables.scss";

.root {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.row {
  display: flex;
  align-items: center;
}

.content {
  padding: 50px 20px;
  width: 100%;
  display: inherit;
  flex-direction: inherit;
  justify-content: inherit;
  align-items: flex-start;
  box-sizing: border-box;
}

.title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-family: AbhayaLibre, "Times New Roman", Times, serif;
  font-size: 35px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333;
  margin-bottom: 60px;
}

.closeButton {
  border: solid 2px #00008f !important;
  border-radius: 50% !important;
  color: #00008f !important;
  padding: 8px !important;
}

.form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: center;
  background-color: white;
  padding: 50px;
  border: solid 1px #e8e8e8;
}

.formRow {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  &__label {
    margin-bottom: 10px;
    font-family: SourceSansPro, Arial, Helvetica, sans-serif;
    text-transform: capitalize;
    font-weight: bold;
    color: #6d6d6d;
  }
}

.formBottom {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;

  &__submitBtn {
    font-family: SourceSansPro, Arial, Helvetica, sans-serif !important;
    border-radius: 0 !important;
    font-size: 14px !important;
    padding: 6px 30px !important;
    background-color: #00008f !important;
    color: white !important;
    font-weight: bold !important;
    width: 180px;
    height: 45px;
  }

  &__uploadBtn {
    font-family: SourceSansPro, Arial, Helvetica, sans-serif !important;
    border-radius: 0 !important;
    font-size: 12px !important;
    padding: 6px 30px !important;
    border: solid 1px #00008f !important;
    color: #00008f !important;
    font-weight: bold !important;
    min-width: 180px;
    height: 45px;
  }

  &__uploadIcon {
    margin-right: 8px;
  }
}

.formInput {
  width: 100%;
  background: white;
  border: solid 1px #b5b5b5;
  height: 40px !important;
  min-width: 200px;
  padding: 0 10px;
  font-size: 10px !important;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.formSelectItem {
  font-size: 12px !important;
}

.formInputError {
  border: solid 2px red;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &__title {
    font-family: SourceSansPro, Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333;
    margin: 10px 0;
  }
}

.documentCount {
  display: flex;
  align-items: center;
  padding-left: 20px;
  box-sizing: border-box;

  &__text {
    text-transform: capitalize;
    font-weight: bold;
    font-size: 14px;
  }
}

.horizDivider {
  width: 100%;
  border-top: solid 1px #b5b5b5;
}
