.root {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.root .icon {
  font-size: 15px;
}

.root .text {
  margin-left: 10px;
}
