.root {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  margin-right: 13px;
}

.img {
  height: 100%;
  object-fit: contain;
}

.clickable {
  cursor: pointer;
}
