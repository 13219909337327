.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 1.6px !important;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
  filter: invert(14%) sepia(49%) saturate(5447%) hue-rotate(238deg)
    brightness(75%) contrast(144%);
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 1.6px !important;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
  filter: invert(14%) sepia(49%) saturate(5447%) hue-rotate(238deg)
    brightness(75%) contrast(144%);
}

.mapboxgl-ctrl-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  border-radius: 1.6px !important;
  width: 35px;
  border: none !important;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1) !important;
}
