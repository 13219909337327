.root {
  width: 100%;
  height: var(--nav-height, 80px);
  border-bottom: solid 1px #e4e8f0;
  background-color: #fff;
  padding-right: 70px;
  padding-left: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

.sticky {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}
