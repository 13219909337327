.root {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  text-decoration: none;
  font-family: SourceSansPro, Helvetica, sans-serif;
  font-size: 15px;
  padding: 0 15px;
  box-sizing: border-box;
  font-weight: normal;
  color: #00008f;
}
