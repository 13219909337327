.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: none;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.subContainer {
  width: 100%;
  height: 100%;
}

.container.navbar {
  height: calc(100% - var(--nav-height, 50px));
}

.container.subnavbar {
  height: calc(100% - var(--subnav-height, 50px));
}

.fixedWidth {
  max-width: var(--content-maxwidth, 800px);
}

.navbar {
  margin-top: var(--nav-height, 50px);
}

.subnavbar {
  margin-top: var(--subnav-height, 50px);
}

.scrollable {
  overflow-y: auto;
  overflow-x: hidden;
}
