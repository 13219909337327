@use "styles/variables.scss";

.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: var(--subnav-height, 60px);
  background: variables.$baseblue;
  padding: 0 10px;
  box-sizing: border-box;
}

.container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1000px;
  font-family: SourceSansPro, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.07px;
  color: #fff;
  text-transform: capitalize;
  font-size: 15px;
}

.linkContainer {
  display: flex;
  height: 100%;
}

.rightContainer {
  display: flex;
}

.placeholder {
  min-width: 50px;
}

.sticky {
  position: fixed;
  z-index: 99;
  top: var(--nav-height, 80px);
  left: 0;
}
