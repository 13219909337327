$white: #fff;
$black: #000;
$transparent: rgba(0, 0, 0, 0);

$color-sapphire: #2425aa;
$color-deep-sapphire: #00005b;
$color-burnt-sienna: #f07662;
$color-axa-blue: #00008f;
$color-silver: #ccc;
$color-warm-blue: #3b3fd8;
$color-warm-grey: #7f7f7f;
$color-wild-sand: #f5f5f5;
$color-mercury: #e5e5e5;
$color-blue-green: #027180;
$color-alabaster: #fafafa;
$color-vineyard-green: #9fd9b3;
$color-noxious-blue: #4976ba;
$color-malachite: #1cc54e;
$color-shy-tomato: #c91432;

/* stylelint-disable */
:export {
  white: $white;
  black: $black;
  wildsand: $color-wild-sand;
  mercury: $color-mercury;
  transparent: $transparent;
  sapphire: $color-sapphire;
  deepsapphire: $color-deep-sapphire;
  burntsienna: $color-burnt-sienna;
  axablue: $color-axa-blue;
  silver: $color-silver;
  warmblue: $color-warm-blue;
  warmgrey: $color-warm-grey;
  bluegreen: $color-blue-green;
  alabaster: $color-alabaster;
  vineyardgreen: $color-vineyard-green;
  noxiousblue: $color-noxious-blue;
  malachite: $color-malachite;
  shytomato: $color-shy-tomato;
}

/* stylelint-enable */
