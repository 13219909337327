.disabled {
  pointer-events: none;
}

.root {
  min-height: 35px;
  display: flex;
  align-items: center;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
}

.root:hover {
  background-color: #f2f2f2;
  cursor: pointer;
}

.iconContainer {
  height: 20px;
  max-height: 20px;
  min-width: 30px;
}

.icon {
  height: 100%;
  margin-right: 10px;
  filter: invert(35%) sepia(0%) saturate(1793%) hue-rotate(268deg) brightness(96%) contrast(79%);
}

.disabled .icon {
  filter: invert(84%) sepia(0%) saturate(693%) hue-rotate(136deg) brightness(84%) contrast(91%);
}

.text {
  font-size: 16px;
  color: #5f5f5f;
}

.disabled .text {
  font-size: 16px;
  color: #afafaf;
}
