@import "styles/colors.scss";

.dropdownMenu {
  width: 200px;
  position: relative;
  top: 0;
  list-style: none;
  text-align: start;
}

.dropdownMenu li {
  background: $color-axa-blue;
  cursor: pointer;
}

.dropdownMenu li:hover {
  background: $color-noxious-blue;
}

.dropdownMenu.clicked {
  display: none;
}

.dropdownLink {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #fff;
  padding: 16px;
}
