.root {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  &__label {
    margin-bottom: 10px;
    font-family: SourceSansPro, Arial, Helvetica, sans-serif;
    font-weight: bold;
    color: #6d6d6d;
  }

  &__label_disabled {
    color: #999;
  }
}

.field_container {
  width: 100%;
  height: 100%;
  display: flex;
}

.field_label {
  margin: auto;
  font-family: SourceSansPro, Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: #6d6d6d;
}

.input {
  width: 100%;
  background: white;
  border: solid 1px #b5b5b5;
  height: 40px;
  min-width: 200px;
  padding: 0 10px;
  font-size: 14px !important;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &:disabled {
    border: solid 1px #ccc;
    background-color: #fafafa;
  }
}

.input_containter_checkbox {
  display: flex;
  height: 40px;
}

.input_checkbox {
  margin: auto;
  width: 100%;
  background: white;
  border: solid 1px #b5b5b5;
  height: 20px;
  min-width: 100px;
  font-size: 14px !important;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &:disabled {
    border: solid 1px #ccc;
    background-color: #fafafa;
  }
}

.errorInput {
  border: solid 2px red;
}

.formInput {
  width: 100%;
  background: white;
  border: solid 1px #b5b5b5;
  height: 30px;
  min-width: 200px;
  padding: 0 10px;
  font-size: 12px !important;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.formSelectItem {
  font-size: 12px !important;
}
