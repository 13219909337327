.close {
  width: 32px;
  height: 32px;
  opacity: 0.3;
  cursor: pointer;
  border: solid 1px #1c1a1a;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: unset;
}

.close:hover {
  opacity: 1;
}

.close::before,
.close::after {
  position: relative;
  left: 15px;
  content: " ";
  height: 20px;
  width: 1px;
  background-color: #2e2e2e;
  display: inline-block;
}

.close::before {
  transform: rotate(45deg);
}

.close::after {
  transform: rotate(-45deg);
}
