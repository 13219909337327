.root {
  width: 100vw;
  height: 100vh;
}

.container {
  background-color: #b5d0ee;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.logo {
  margin-top: 80px;
}

.text {
  font-size: 35px;
  color: #00008f;
  font-family: AbhayaLibre, "Times New Roman", Times, serif;
  font-weight: bold;
  text-align: center;
}

.subText {
  font-family: SourceSansPro, Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  text-transform: uppercase;
  margin-top: 5px;
  color: #5f5f5f;
  text-align: center;
}

.horizDivider {
  border-bottom: solid 1px #4976ba;
  min-width: 200px;
  max-width: 800px;
  width: 100%;
  height: 1px;
  margin: 30px 0;
}

.button {
  background: #ff735c !important;
  color: white !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  width: 250px;
  height: 50px;
  margin-bottom: 30px !important;
  margin-right: 15px !important;

  &__blue {
    width: 200px;
    height: 40px;
    font-size: 12px !important;
    color: white !important;
    background: #00008f !important;
  }
}
