.container {
  display: flex;
  align-items: center;
}

.input {
  width: 320px;
  height: 41px;
  border: solid 1px #ccc;
  background-color: #fff;
  margin-bottom: 18px;
  padding-left: 5px;

  // IE/Edge adds a "show password" button on the field.
  // We want to remove it because we already provide our own
  &::-ms-reveal {
    display: none;
  }
}

.icon {
  margin-left: 10px;
  margin-top: -20px;
}

.errorInput {
  border: solid 1px #f40026;
}
