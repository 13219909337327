.select {
  display: flex !important;
  align-items: center;
  padding-left: 5px !important;
  padding-right: 15px !important;
  border: solid 1px #e3e3e3 !important;
  border-radius: 2px !important;
}

.item {
  display: flex;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15px;
  width: 30px;
  margin-right: 5px;
  min-width: 0 !important;
}

.icon {
  height: 100%;
  object-fit: contain;
}
