.root {
  position: relative;
  display: inline-block;
}

.container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;

  --circle-width: 40px;

  width: var(--circle-width);
  height: var(--circle-width);
  min-width: var(--circle-width);
  min-height: var(--circle-width);
  border-radius: 70px;
  position: relative;
  background: #00008f;
  color: white;
  background-image: url("../assets/avatar-placeholder.png");
  background-size: cover;
  font-size: 16px;
  font-weight: bold;
}

.avatarBig {
  --circle-width: 100px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--circle-width);
  height: var(--circle-width);
  font-size: 40px;
  border-radius: 70px;
  position: relative;
  background: #00008f;
  color: white;
  background-image: url("../assets/avatar-placeholder.png");
  background-size: cover;
  font-weight: bold;
}

.textBig {
  color: #00008f;
  font-size: 15px;
  font-weight: bold;
  line-height: 25px;
  margin-top: 5px;
}

.subTextBig {
  color: #5f5f5f;
  font-size: 13px;
  text-transform: lowercase;
  line-height: 20px;
  margin-top: 5px;
}

.text {
  font-family: SourceSansPro, Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #333;
  margin-left: 10px;
}

.divider {
  width: 95%;
  height: 0;
  border: 1px solid #f9f9f9;
}

.down {
  border: solid #00008f;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-left: 8px;
}

.dropdownContent::before {
  content: "";
  position: absolute;
  right: 25px;
  top: -6px;
  width: 0;
  height: 0;
  border-width: 0 8px 8px;
  border-style: solid;
  border-color: transparent transparent #fff;
  z-index: 1;
}

.dropdownContent {
  padding-top: 20px;
  padding-bottom: 20px;
  position: absolute;
  right: 0;
  background-color: #fff;
  min-width: 300px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 20%);
  z-index: 2;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.userInfoContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 15px;
  margin-bottom: 10px;
}

.itemsPlaceholder {
  min-height: 50px;
  width: 100%;
}
